/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import configData from "../../config.json";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

// core components

function SectionCIO() {
  useEffect(() => {
    getData();
  }, []);
  const [dataToshow, setDatatoShow] = useState([]);
  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
   let dollarUSLocale = Intl.NumberFormat("en-US");

  const getData = () => {
    axios
      .get(configData.CIO_URL + "/api/api/summary_list.php")
      .then((Response) => setDatatoShow(Response.data));
  };

  // console.log(configData.CIO_URL + "/api/api/summary_list.php");

  return (
    <>
      <Container>
        <Row>
          <Col md="12">
            <div className="title">
              <h2 className="text-primary">
                การจัดหาระบบคอมพิวเตอร์ มหาวิทยาลัยศิลปากร
              </h2>
              <h4>
                ระบบสนับสนุนการบริหารงานของผู้บริหารเทคโนโลยีสารสนเทศระดับสูง
                มหาวิทยาลัยศิลปากร (ระบบ CIO)
              </h4>
              <br />
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="mt-0" md="6">
            <div className="title">
              <h3>
                รอบดำเนินการปัจจุบัน{" "}
                <span className="text-primary" style={{ fontWeight: "bold" }}>
                  {dataToshow.current_term}
                </span>
              </h3>
            </div>
            <label className="label label-primary mr-1">วันที่เริ่ม</label>
            {dataToshow.current_term_start}
            <label className="label label-danger mr-1 ml-2">
              วันที่สิ้นสุด
            </label>
            {dataToshow.current_term_end}
            <p className="mt-3">
              <p>
                <i className="nc-icon nc-bank mr-1 ml-5" />
                <span
                  style={{ fontWeight: "bold" }}
                  className="text-dark mr-1 ml-2"
                >
                  บันทึกข้อมูลแล้ว{" "}
                  <label className="label label-success mr-1 ml-2">
                    {dataToshow.enter_count}
                  </label>
                  คณะวิชา/หน่วยงาน
                </span>
              </p>
              <p>
                <i className="nc-icon nc-bank mr-1 ml-5" />
                <span
                  style={{ fontWeight: "bold" }}
                  className="text-dark mr-1 ml-2"
                >
                  ยืนยันข้อมูลแล้ว{" "}
                  <label className="label label-primary mr-1 ml-2">
                    {dataToshow.submit_count}
                  </label>
                  คณะวิชา/หน่วยงาน
                </span>
              </p>
              <p>
                <i className="nc-icon nc-tv-2 mr-1 ml-5" />
                <span
                  style={{ fontWeight: "bold" }}
                  className="text-dark mr-1 ml-2"
                >
                  จำนวนรายการ
                  <label className="label label-info mr-1 ml-2">
                    {dataToshow.summary_item}
                  </label>
                  รายการ
                </span>
              </p>
              <p>
                <i className="nc-icon nc-money-coins mr-1 ml-5" />
                <span
                  style={{ fontWeight: "bold" }}
                  className="text-dark mr-1 ml-2"
                >
                  งบประมาณทั้งสิ้น
                  <label className="label label-danger mr-1 ml-2">
                    {dollarUSLocale.format(dataToshow.summary_budget)}
                  </label>
                  บาท
                </span>
              </p>
            </p>
          </Col>
          <Col className="mt-4" md="6">
            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <Nav id="tabs" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      ประกาศจากระบบฯ
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      เอกสารดาวน์โหลด
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <p className="text-dark text-md-left mb-4">
                  {dataToshow.sys_memo}
                </p>
              </TabPane>
              <TabPane tabId="2">
                <ul className="text-left">
                  <li><a href="https://silpakorn.sharepoint.com/:b:/s/suPlanWebStorage/EWaApMA-2URCmfchFDWII2cBScTZlz6rRzYuu5ltWBSQog?e=9W7VGe">เกณฑ์ราคากลางและคุณลักษณะพื้นฐานการจัดหาอุปกรณ์และระบบคอมพิวเตอร์ ฉบับเดือนมีนาคม 2566</a></li>
                  <li><a href="https://silpakorn.sharepoint.com/:b:/s/suPlanWebStorage/EaYbNAUTX6BKqqfs0Xr_obMBNYlXW83ATyfZS5gAAJCXjQ?e=QU0Mo3">เกณฑ์ราคากลางและคุณลักษณะพื้นฐานของระบบกล้องโทรทัศน์วงจรปิด ฉบับเดือนมิถุนายน 2564</a></li>
                  <li>
                    <a href="https://silpakorn.sharepoint.com/:b:/s/suPlanWebStorage/EQc5qBaJx3lJiPxYISzfz3UBEvZ9ZZhTUgnZ06Hrj-r1pQ?e=kMO5vQ">
                      ปฏิทินรอบการดำเนินงาน พ.ศ.2567
                    </a>
                  </li>
                  <li>
                    <a href="https://silpakorn.sharepoint.com/:b:/s/suPlanWebStorage/ESgS-fsvvXFNgxULnFIhIUkBqTFoQxPXzqfSNpD-a_n92w?e=n3hv4e">
                      คู่มือการใช้งานระบบ CIO
                    </a>
                  </li>
                </ul>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        <Row className="mt-5">
          <Button color="primary" size="sm" href={configData.CIO_URL}>
            เข้าสู่ระบบ CIO
          </Button>{" "}
          <Button
            className="ml-5"
            color="warning"
            size="sm"
            href={configData.CIO_URL + "/rating/input.php"}
          >
            ประเมินความพึงพอใจระบบ CIO
          </Button>{" "}
        </Row>{" "}
      </Container>
    </>
  );
}

export default SectionCIO;
