import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  CardSubtitle,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";

import planDirector from "../../assets/img/faces/11.png";
import dep0 from "../../assets/img/faces/dep0_0.jpg";
import dep1 from "../../assets/img/faces/dep1_0.jpg";
import dep2 from "../../assets/img/faces/dep2_0.jpg";
import dep3 from "../../assets/img/faces/dep3_0.jpg";

// import dep1_1 from "../../assets/img/faces/13022.jpg";
import dep1_2 from "../../assets/img/faces/dep1_2.jpg";
import dep1_3 from "../../assets/img/faces/dep1_3.jpg";
import dep1_4 from "../../assets/img/faces/dep1_4.jpg";
import dep1_5 from "../../assets/img/faces/louis.png";
import dep1_6 from "../../assets/img/faces/sila.jpg";

import dep2_2 from "../../assets/img/faces/dep2_2.jpg";
import dep2_3 from "../../assets/img/faces/dep2_3.jpg";
import dep2_4 from "../../assets/img/faces/dep2_4.jpg";
import dep2_5 from "../../assets/img/faces/dep2_5.png";
import dep2_6 from "../../assets/img/faces/dep2_6.JPG";
import dep2_7 from "../../assets/img/faces/pong.jpg";
import dep2_8 from "../../assets/img/faces/556405515.png"

import dep3_2 from "../../assets/img/faces/dep3_2.jpg";
import dep3_3 from "../../assets/img/faces/dep3_3.png";
import dep3_4 from "../../assets/img/faces/dep3_4.jpg";
import dep3_5 from "../../assets/img/faces/dep3_5.jpg";
import dep3_6 from "../../assets/img/faces/dep3_6.jpg";
import dep3_7 from "../../assets/img/faces/dep3_7.jpg";

import phone from "../../assets/img/faces/phone.png";

var cardStyle = {
  display: "block",
  transitionDuration: "0.3s",
};

function SpiningCard() {
  return (
    <>
      <div className="section section-navbars">
        <Container>
          <Row>
            <Col md="4"></Col>
            <Col md="4">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={planDirector} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวเสาวณีย์ คูพูลทรัพย์</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        {" "}
                        ผู้อำนวยการกองแผนงาน
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>KUPULSUB_S@SU.AC.TH</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100081</CardText>
                </CardFooter>
              </Card>
            </Col>
            <Col md="4"></Col>
          </Row>
          <Row>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep0} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางจุฑารัตน์ แก้วโหมดตาด</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        ผู้ช่วยผู้อำนวยการกอง
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>kaewmodetard_j@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100082</CardText>
                </CardFooter>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep1} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวอริยา ทรงประไพ</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        หัวหน้างานวิเคราะห์งบประมาณ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>songprapai_a@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100092</CardText>
                </CardFooter>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวกฤติญา จันทพันธ์</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        หัวหน้างานยุทธศาสตร์และวิจัยสถาบัน
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>chantapun_s@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100102</CardText>
                </CardFooter>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep3} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายนิรันดร์ แสงพุ่ม</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        รักษาการแทนหัวหน้างานวางแผนและพัฒนากายภาพ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>sangphum_n@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100112</CardText>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
          <h4 className="title text-center">บุคลากร</h4>
          <Row>
            <h5>งานวิเคราะห์งบประมาณ</h5>
          </Row>
          <Row>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep1} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวอริยา ทรงประไพ</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        หัวหน้างานวิเคราะห์งบประมาณ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>songprapai_a@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100092</CardText>
                </CardFooter>
              </Card>
            </Col>

            {/* <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep1_1} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายนรเสฎฐ์ วิเศษสิงห์</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>wisedsing_n@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100093</CardText>
                </CardFooter>
              </Card>
            </Col> */}

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep1_2} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาววราภรณ์ แซ่ลิ้ม</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>saelim_w8@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100095</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep1_3} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางอำนวย ช่วยพันธ์</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>chuaiphan_a@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100093</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep1_4} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวพชรมณ เลาหล่าย</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>laolay_p@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 200020</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep1_5} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวณัฐฐา ถิรโสภี</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>theerasopee_n@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100093</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2_8} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายอิทธิพัทธ์ คล้ายปาน</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>KLAYPARN_I@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100093</CardText>
                </CardFooter>
              </Card>
            </Col>

           
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep1_6} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายศิลา สังข์จุ้ย</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักคอมพิวเตอร์ชำนาญการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>sungjuic_w@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100095</CardText>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className="mt-5">
          <Row>
            <h5>งานยุทธศาสตร์และวิจัยสถาบัน</h5>
          </Row>
          <Row>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวกฤติญา จันทพันธ์</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        หัวหน้างานยุทธศาสตร์และวิจัยสถาบัน
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>chantapun_s@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100102</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2_2} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">
                        นางสาววราภรณ์ วิเศษพานิชกิจ
                      </CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>wisetpanichkit_w@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100103</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2_3} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">
                        นางสาวเบญจมาศ <br /> ขุนประเสริฐ{" "}
                      </CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>khunprasert_b@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100104</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2_4} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายฉัตรชัย ศิริสมบูรณ์ลาภ</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>sirisomboonlarp_c@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100019</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2_6} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวอริษา พิภพศิริรัตน์</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>pipobsirirat_a@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100103</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2_7} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายกิติพงษ์ เท้งสี</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>thangsi_k@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100105</CardText>
                </CardFooter>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2_5} />  
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวพชรพร อิ่มชื่น</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>imchuen_p@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100105</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              {/* <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep2_8} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายพงศกร ศรีรงค์ทอง</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>srirongthong_p@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100014</CardText>
                </CardFooter>
              </Card> */}
            </Col>

            <Col md="3">
              {/* <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={budsabakon_1} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวบุษบากร อิ่มใจดี</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        นักวิชาการอุดมศึกษาปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>aimjaidee_b@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100104</CardText>
                </CardFooter>
              </Card> */}
            </Col>
          </Row>
        </Container>

        <Container className="mt-5">
          <Row>
            <h5>งานวางแผนและพัฒนากายภาพ</h5>
          </Row>
          <Row>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep3} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายนิรันดร์ แสงพุ่ม</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        รักษาการแทนหัวหน้างานวางแผนและพัฒนากายภาพ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>sangphum_n@su.ac.th</CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100112</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep3_2} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นางสาวสุชิน สิงหเดช </CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        ช่างเขียนแบบชำนาญงาน
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>singhaded_s@su.ac.th</CardText>
                  <CardText>โทร. (034) 25 3848 ต่อ 200020</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep3_3} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">
                        นางสาวณัฏฐภัค <br />
                        ทรัพย์สมบูรณ์{" "}
                      </CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        สถาปนิกปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>supsomboon_n@su.ac.th</CardText>
                  <CardText>โทร. 0 2105 4686 ต่อ 100115</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep3_4} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายจารึก จ่ากลาง</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        วิศวกรปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>jaklang_j@su.ac.th</CardText>
                  <CardText>โทร. (034) 25 3848 ต่อ 200020</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep3_5} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายนาถวุฒิ ศรีเกตุ</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        วิศวกรปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>srikate_n@su.ac.th</CardText>
                  <CardText>โทร. 0 2105 4686 ต่อ 100113</CardText>
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep3_6} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายยุทธพงษ์ วัฒนกุลเจริญ</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        สถาปนิกปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>wattanakunjarou_y@su.ac.th</CardText>
                  <CardText>โทร. 0 2105 4686 ต่อ 100115</CardText>
                </CardFooter>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={phone} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นาย ภูริณัฐ ศรีวิบูลย์</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        วิศวกรปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>SRIWIBOON_P@SU.AC.TH</CardText>
                  <CardText>โทร. 0 2105 4686 ต่อ 100115</CardText>
                </CardFooter>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep3_7} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">
                        นายณรงค์เดช สุนทรกุล ณ ชลบุรี
                      </CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        ช่างเขียนแบบปฏิบัติการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>soontrongul_n@su.ac.th</CardText>
                  <CardText>โทร. 0 2105 4686 ต่อ 100115</CardText>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className="mt-5">
          {/* <Row>
            <h5>กองแผนงาน</h5>
          </Row> */}
          {/* <Row>
            <Col md="3">
              <Card className="card-profile">
                <div className="card-avatar">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img alt="..." src={dep0_1} />
                  </a>
                </div>
                <CardBody style={cardStyle}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <div className="author">
                      <CardTitle tag="h5">นายวิรัตน์ คำมีภักดี</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">
                        พนักงานธุรการ
                      </CardSubtitle>
                    </div>
                  </a>
                </CardBody>
                <CardFooter className="text-center">
                  <CardText>
                    kammeepaqdee_v@su.ac.th
                  </CardText>
                  <CardText>โทรศัพท์ :0 2105 4686 ต่อ 100097</CardText>
                </CardFooter>
              </Card>
            </Col>
          </Row> */}
          <Row className="text-center">
            <Col>
              <Link to="/">
                <Button outline color="danger" size="sm">
                  กลับหน้าหลัก
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default SpiningCard;
